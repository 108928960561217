




import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Popup } from "vant";
@Component({
  components: {
    Popup,
  },
})
export default class PopDetail extends Vue {
  @Prop() show!:boolean
  async created() {}
}
