



































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import MediaCard from "@/views/weplay-media/components/MediaCard.vue";
import PopDetail from "@/views/weplay-media/components/PopDetail.vue";
import { GameDataItem, GameVendorItem } from "@/types/global-state.type";
import { Popup, ImagePreview } from "vant";
import { done } from "nprogress";
import GlobalStateStore from "@/store/modules/global-state.store";
import { getModule } from "vuex-module-decorators";

@Component({
  components: {
    MediaCard,
    PopDetail,
    Popup,
  },
})
export default class WeplayMedia extends Vue {
  wideHigh: number | undefined;
  show = false;
  game_vendor_json: GameVendorItem[] = [];
  get zhiboBox(){
    const { clientWidth } = document.body
    return `background: linear-gradient(136deg, #ADF1A5 0%, #FBB093 68%, #FDB7DB 100%);margin:0 auto;border-radius:2px;
    width:${clientWidth - 32}px;height:${(clientWidth - 32)/1.7}px;display:flex;justify-content:center;align-items:center;`
  }

  get zhiboStyle(){
    const { clientWidth } = document.body
    return `width:${clientWidth - 42}px;height:${(clientWidth - 42)/1.734}px;margin:auto;`
  }
  theNewData = [
    {
      title: "2022 WePlay x 游戏电波特别话事节目，超正经的年度游戏大赏11月7日话题公开！",
      date: " 2022-11-02",
      id: "30996",
      img: "https://cdn.vgn.cn/static/game_vendor/20221102-163826.jpeg",
    },
    {
      title:
        "WePlay 2022游戏周11.7-13日举办！汇集全球优质游戏内容",
      date: " 2022-11-02",
      id: "30997",
      img: "https://cdn.vgn.cn/static/game_vendor/20221102-163822.png",
    },
  
   
  ];
  globalState = getModule(GlobalStateStore);
  toSign(){
    location.href = 'https://wj.qq.com/s2/11026359/cb52'
  }
  initScriptGameData() {
    const script = document.createElement("script");
    script.src = "https://cdn.vgn.cn/static/game_vendor/game_vendor.js?t=5";
    document.querySelector("head")?.appendChild(script);
    const timer = setInterval(() => {
      // @ts-ignore
      if (window["game_vendor_json"]) {
        clearInterval(timer);
        // @ts-ignore
        this.game_vendor_json = window["game_vendor_json"];
        console.log(this.game_vendor_json)

      }
    }, 100);
  }
  toGetP() {
    location.href =
      "https://live.bilibili.com/21462472";
  }
  async created() {
    this.initScriptGameData();
    const { clientWidth, offsetWidth } = document.body;
    this.wideHigh = (clientWidth || offsetWidth) / 2 - 28;
    this.globalState.setLoginTip(false);
  }
  popData: any = {};
  openScreenshots(item: any) {
    ImagePreview([item]);
  }
  showPop(item: GameDataItem) {
    this.popData = item;
    this.show = true;
    this.$nextTick(() => {
      document.querySelectorAll(".game-intro img").forEach((el) =>
        el.addEventListener("click", (e) => {
          try {
            this.openScreenshots((e.target as any)?.src);
          } catch (e) {}
        })
      );5
    });
  }
}
