








import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
    components:{
    }
})
export default class MediaCard extends Vue {
@Prop() private readonly wideHigh!:number
@Prop() private readonly item!:any
  toArticlePage(){
    this.$router.push({name:'articepage',params:{
      id:this.item.id
    }})
  }
  async created() {
      
  }
 
}
